<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>Configure Lookups</h1>
      <div class="btns-container">
        <div id="lookup-selection" class="md-layout-item vasion-flex-end">
          <VasionButton
            v-if="lookupsAvailable"
            id="new-lookup-header-button"
            :classProp="'primary'"
            class="last-btn"
            @vasionButtonClicked="toggleLookupSelection"
          >
            New Lookup
          </VasionButton>
          <div
            v-if="displayLookupSelection"
            id="lookup-picker"
            v-click-outside="toggleLookupSelection"
          >
            <div class="group-row">
              <VasionButton
                classProp="text-black"
                @vasionButtonClicked="newAttributeFormClick"
              >
                <label>Database Lookup</label>
              </VasionButton>
              <VasionButton
                classProp="text-black"
                @vasionButtonClicked="newSalesForceFormClick"
              >
                <label>SalesForce Lookup</label>
              </VasionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="lookupsAvailable && tableData && !loadingLookups" class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="ColumnValues"
          :hideColumns="hiddenColumns"
          :tableRows="tableData.Rows && tableData.Rows.Values"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
      <div v-else-if="!loadingLookups" class="no-items-div">
        <VasionEmptyForm />
        <div class="vasion-large-message">
          {{ greetingMessage }}
        </div>
        <div class="vasion-page-subheader instructional-message-div">
          It feels a little empty, create a Database lookup or a SalesForce lookup to get started!
        </div>
        <div class="button-div">
          <VasionButton
            id="new-lookup-button"
            :isFullWidth="true"
            :classProp="'primary'"
            @vasionButtonClicked="newAttributeFormClick"
          >
            <span class="vasion-button-plus">+</span>Database Lookup
          </VasionButton>
          <VasionButton
            id="new-salesforce-button"
            :isFullWidth="true"
            :classProp="'primary'"
            @vasionButtonClicked="newSalesForceFormClick"
          >
            <span class="vasion-button-plus">+</span>SalesForce Lookup
          </VasionButton>
        </div>
      </div>
    </div>
    <md-dialog id="confirmLookupDelete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="deleteLookupAndToggle()" />
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: 'DatabaseLookups',
  data: function () {
    return {
      ColumnValues: [],
      deleteLookupId: 0,
      deleteLookupName: '',
      displayLookupSelection: false,
      greetingMessage: 'Hello!',
      headerCheckboxToggled: false,
      hiddenColumns: ['lookupID'],
      loadingLookups: true,
      showDeleteDialog: false,
      tableData: {},
    }
  },
  computed: {
    deletePromptMessage() { return `Are you sure you want to delete "${this.deleteLookupName}"?` },
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    lookupsAvailable() { return this.lookupList && this.lookupList.length > 0 },
    lookupList() { return this.$store.state.fieldMapping.lookupList },
  },
  async created() {
    this.ColumnValues = [
      'lookupID',
      'Lookup Name',
      `Fill Data in ${$formsLabel}`,
      'Lookup Type',
      'Default Lookup',
      '_VasionEllipsisButton_',
    ]

    const promises = await Promise.all([
      this.$store.dispatch('fieldMapping/getDatabaseLookups', true),
      this.$store.dispatch('common/getEmptyAppGreeting'),
    ])
    // eslint-disable-next-line prefer-destructuring
    this.greetingMessage = promises[1]
    this.loadingLookups = false

    this.$store.dispatch('attributeForm/getForms')
    this.$store.dispatch('fieldMapping/getDatabaseConnections')

    this.refreshTable()
    await this.$store.dispatch('security/setEllipsisButtonConfig', 'field-mapping')
  },
  methods: {
    async deleteLookupAndToggle() {
      if (this.deleteLookupId > 0) {
        if (await this.$store.dispatch('fieldMapping/deleteDatabaseLookup', this.deleteLookupId)) {
          await this.$store.dispatch('fieldMapping/getDatabaseLookups', true)
          await this.refreshTable()
        }
      }
      this.deleteLookupId = 0
      this.deleteLookupName = ''
      this.toggleDeleteDialog()
    },
    async editLookup(lookupId, lookupType) {
      switch (lookupType){
        case 'Database':
          this.$router.push({ name: 'EditDatabaseLookup', params: { lookupId: lookupId } })
          break;
        case 'SalesForce':
          this.$router.push({ name: 'EditSalesForceLookup', params: { lookupId: lookupId } })
          break;
        default:
          console.warn(`Unknown lookup type: ${lookupType}`)
          break;
      }
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-field-mapping-button':
            this.editLookup(id, payload.item.Values[3])
            break;
          case 'delete-field-mapping-button':
            this.deleteMessage = `Are you sure you want to delete "${name}"?`
            this.setDeleteValues(id, name)
            break;
          default:
            break;
        }
      }
    },
    newAttributeFormClick() { this.$router.push({ name: 'EditDatabaseLookup', params: { lookupId: 0 } }) },
    newSalesForceFormClick() { this.$router.push({ name: 'EditSalesForceLookup', params: { lookupId: 0 } }) },
    refreshTable() {
      this.tableData.Rows = {
        Values: [],
      }

      this.tableData.Rows.Values = this.lookupList.map((lookupElement) => {
        const dataRow = [
          lookupElement.id,
          lookupElement.name,
          lookupElement.attributeFormName,
          lookupElement.lookupType === 'Database' ? 'Database' : 'SalesForce',
          lookupElement.defaultLookup ? 'Yes' : 'No',
          'VasionEllipsisIcon',
        ]

        const retElement = {
          Values: dataRow,
          ValueType: 'String',
        }

        return retElement
      })
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.editLookup(data.Values[0], data.Values[3])
    },
    setDeleteValues(lookupId, lookupName) {
      this.deleteLookupId = lookupId
      this.deleteLookupName = lookupName
      this.toggleDeleteDialog()
    },
    toggleDeleteDialog() { this.showDeleteDialog = !this.showDeleteDialog },
    toggleLookupSelection() { this.displayLookupSelection = !this.displayLookupSelection },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

#lookup-selection {
  position: relative;
  & #lookup-picker {
    @include Text;
    position: absolute;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 3px 5px 0 $shadow;
    top: 45px;
    right: 4px;
    z-index: 40;
  }
}

.button-div {
  margin: auto;
  margin-top: 30px;
  width: 288px;
}
  
.instructional-message-div {
  margin-top: 24px;
}
  
.no-items-div {
  margin-top: 60px;
}

.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
  text-align: left;
}
</style>
